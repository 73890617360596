$font-family: 'Noto Sans CJK KR';
$mainColor: #705DF6;
p{
    margin: 0;
    poadding: 0;
}
#app{
    max-width: 768px;
    height: 100%;
    margin: 0 auto;
    // border: 1px solid #fff;
    padding: 0 16px;
    padding-bottom: 100px;
}
#top{
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconContainer{
        display: flex;
        align-items: center;
        .globalImg{
            width: 20px;
            height: 20px;
            padding: 10px;
            margin-left: auto;
            cursor: pointer;
        }   
    }
}
#header{
    margin-top: 24px;
    margin-bottom: 30px;
    .pathName{
        font-size: 30px;
        font-weight: 600;
        color: $mainColor;
    }
    .navi{
        display: flex;
        align-items: center;
        cursor: pointer;
        .top-menu{
            font-size: 16px;
            margin-top: 12px;
            margin-right: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            &.active{
                color: $mainColor;
                font-weight: 600;
            }
            .menudot{
                width: 4px;
                height: 4px;
                border-radius: 4px;
                margin-top: 4px;
                background-color: none;
                &.active{
                background-color: $mainColor;                
                }
            }
        }
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// 

.backIcon{
    color: #888;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 16px;
}
.custompage{
    text-align: center;
    font-size: 12px;
    .customPageNum{
        &.active{
            color: $mainColor;
        }
    }
}
.formBox{
    width: 320px;
    height: 48px;
    background-color: #fff;
    border: 1px solid #b5b5b5;
    display: flex;
    align-items: center;
    border-radius: 48px;
    margin-bottom: 16px;
    input{
        background-color: inherit;
        width: 80%;
        border: none;
        outline: 0;
        color:#fff;
    }
}
.loginBtn{
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    border-radius: 48px;
    height: 48px;
    width: 320px;
    margin-top: 48px;
    box-shadow: none;
    outline: 0;
    background-color: $mainColor;
    border: none;
    cursor: pointer;
    color: #fff;
}
.ant-pagination{ 
    .ant-pagination-prev, 
    .ant-pagination-next{
        span{
        color: #888;
        }
    }
    .ant-pagination-item{
        border:none;        
        & > a {
            color:#888;
        }
    }
    .ant-pagination-item.ant-pagination-item-active > a, 
    .ant-pagination-item.ant-pagination-item-active:hover > a {
        color: $mainColor !important;
    }
}
#login, #singup, #modifyPw, #signupok{
    .header{
        height: 56px;
        display: flex;
        align-items: center;
        .globalImg{
            width: 20px;
            height: 20px;
            padding: 10px;
            margin-left: auto;
            cursor: pointer;
        }       
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#login {    
    .container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .logoImg{
            width: 240px;
            margin-bottom: 40px;
        }      
        .loginOptionBox{
            width: 320px;
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: space-between; 
            cursor: pointer;
            .option{
                display: flex;
                align-items: center;
                margin: 0 10px;
                .optionActive{
                    color:$mainColor;
                }
            }
            .findPw, .signup{
                width: 50%;
                text-align: center;
                font-size: 12px;
                line-height: 32px;
                margin-top: 16px;
            }           
            .slash{
                text-align: center;
                font-size: 12px;
                line-height: 32px;
                margin-top: 16px;
            }
            .signup{
                color: #888;
            }
        }        
    }
}
.ant-popover-content{
    margin-right: 16px;
    .pophover{
        font-size: 16px;
        &>div{
            margin: 4px 0;
        }
        .lan{
            font-family: $font-family;
            cursor: pointer;
            font-weight: 300;
            text-align: center;
            color: #888;
            &.active{
                color: #000;
            }
        }
    }
}
#currency{
    width: 100%;
    .notiTitle{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .mainTitle{
            color: #000;
        }
        .moreBtn {
            border: 1px solid #888;
            width: 20px;
            height: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            cursor: pointer;
        }
        .subTitle{
            font-size: 14px;
        }
    }
    .notiTableContainer{
        color: #fff;
        border-top: 1px solid #888;
        .notiTable{
            border-bottom: 1px solid #888;
            line-height: 46px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .idx{
                color: #888;
                width: 10%;
                text-align: center;
            }
            .title{
                color: #888;
                width: 75%;          
                display: block;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .data{
                width: 15%;
                color: #888;
                font-size: 14px;
                text-align: center;
            }          
        }
    }
    @media screen and (min-width: 600px) {
        .coinTableContainer{
            .coinTable{
                background: linear-gradient(45deg, rgba(252,253,142,0.5), rgba(151,253,186,0.3), rgba(188,255,232,0.5));
                margin-bottom: 12px; 
                border-radius: 10px;
                padding: 12px 16px;
                cursor: pointer;
                .container{               
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .title{
                        display: flex;
                        align-items: center;
                        color:#000;
                        font-size: 18px;
                        font-weight: 500;
                        .coinImg{
                            background-color: #fff;
                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            margin-right: 16px;
                            img{
                                width: auto;
                                height: auto;
                                max-width: 32px;
                                max-height: 32px;
                            }
                        }
                    }
                    .balance{
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: #000;
                        .price{
                            font-weight: 500;
                            font-size: 24px;
                        }
                        .lockBalance{
                            font-weight: 500;
                            font-size: 20px;
                            color: #fff;
                            background-color: #b7adfd;
                            padding: 5px 10px;
                            border-radius: 5px;
                            align-self: flex-end;
                        }
                        .rate{
                            margin-left: 16px;
                        }
                    }
                }
                .coin-graph{
                    margin-top: 16px;
                    tspan{
                        font-size: 14px;
                    }
                }
            }        
        }
    }
    @media screen and (max-width: 599px) {
        .coinTableContainer{
            .coinTable{     
                cursor: pointer;
                background: linear-gradient(45deg, rgba(252,253,142,0.5), rgba(151,253,186,0.3), rgba(188,255,232,0.5));
                &.active{           
                    background: linear-gradient( to bottom, #fff3, #000 );
                }
                margin-bottom: 12px; 
                border-radius: 10px;
                padding: 12px 16px;
                .container{               
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .title{
                        display: flex;
                        align-items: center;
                        color:#000;
                        font-size: 18px;
                        font-weight: 500;
                        .coinImg{
                            background-color: #fff;
                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            margin-right: 10px;
                            img{
                                width: auto;
                                height: auto;
                                max-width: 32px;
                                max-height: 32px;
                            }
                        }
                    }
                    .balance{               
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        text-align: right;
                        color:#000;
                        .price{
                            font-weight: 500;
                            font-size: 22px;
                        }
                        .lockBalance{
                            font-weight: 500;
                            font-size: 18px;
                            color: #fff;
                            background-color: #b7adfd;
                            padding: 5px 10px;
                            border-radius: 5px;
                            align-self: flex-end;
                        }
                        .rate{
                            margin-left: 16px;
                        }
                    }
                }
                .coin-graph{
                    margin-top: 16px;                  
                    tspan{
                        font-size: 14px;
                    }
                }
            }        
        }
    }
}
#notice,
#inquiry{
    .notiTitle{
        color: #000;
        margin-top: 16px;
    }
    .notiTableContainer{
        margin: 16px 0;
        .notiTable{
            cursor: pointer;
            .notice{
                background-color: #f7f6f3;
                border-radius: 10px;
                padding: 16px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            .newTag{
                color: #7e2cff;
                border: 1px solid #7e2cff;
                width: 40px;
                font-size: 14px;
                text-align: center;
                border-radius: 36px;
            }
            .notiTag{
                color: $mainColor;
                border: 1px solid $mainColor;
                width: 60px;
                font-size: 14px;
                text-align: center;
                border-radius: 36px;
            }
            .title{
                color: #888;
                margin-left: 10px;
                width: 65%;          
                display: block;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .date{
                font-size: 14px;
                text-align: right;
                margin-left: auto;
            }
            .content{
                font-size: 16px;
                color: #888;
                padding: 16px;
                border: 1px solid #fff3;
                border-radius: 10px;
                margin-bottom: 12px;
            }
        }    
    }
}
#mywallet{
    .mybalance{
        border: 2px solid #fff3;
        border-radius: 10px;
        padding: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        // border: 2px solid $mainColor;
        background-color: rgba(112,93,246,0.2);
     
        // background: linear-gradient(45deg, #fcfd8e, #97fdba, #b1ffe8);
        .balance{
            font-size: 24px;
            font-weight: 600;
            color: $mainColor;
            margin-left: auto;
            .won{
                font-size: 24px;
            }
        }
    }
    .myAvailablebalance{
        border: 2px solid #fff3;
        border-radius: 10px;
        padding: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        background-color: $mainColor;
        // background: linear-gradient(45deg, #fcfd8e, #97fdba, #b1ffe8);
        .balance{
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            font-weight: 500;
            margin-left: auto;
            .won{
                font-size: 24px;
            }
        }
    }
    .coinTable{
        background: linear-gradient(45deg, rgba(252,253,142,0.5), rgba(151,253,186,0.3), rgba(188,255,232,0.5));
        margin-bottom: 12px; 
        border-radius: 10px;
        padding: 12px 16px;
        cursor: pointer;
        .container{               
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            .title{
                display: flex;
                align-items: center;
                color:#000;
                font-size: 18px;
                font-weight: 500;
                .coinImg{
                    background-color: #fff;
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin-right: 16px;
                    img{
                        width: auto;
                        height: auto;
                        max-width: 32px;
                        max-height: 32px;
                    }
                }
            }
            .balance{
                display: flex;
                flex-direction: column;
                text-align: right;
                color: #000;
                .price{
                    font-weight: 500;
                    font-size: 20px;
                }
                .lockBalance{
                    font-weight: 500;
                    font-size: 16px;
                    color: #fff;
                    background-color: #b7adfd;
                    padding: 5px 10px;
                    border-radius: 5px;
                    align-self: flex-end;
                }
                .rate{
                    margin-left: 16px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #000;
                }
            }
        }
        .coin-graph{
            margin-top: 16px;
            tspan{
                font-size: 14px;
            }
        }
    }        
}
#walletDetail{
    .coinBox{
        border-radius: 10px;
        background: linear-gradient(90deg, rgba(252,253,142,0.5), rgba(188,255,232,0.5));
        padding: 16px;
        margin-bottom: 16px;
        .coin{
            display: flex;
            align-items: center;
            font-size: 25px;
            .coinImg{
                background-color: #fff;
                width: 64px;
                height: 64px;
                border-radius: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-right: 16px;
                img{
                    width: auto;
                    height: 100%;
                    max-width: 64px;
                    max-height: 64px;
                }
            }
        }    
        .balance{
            margin-left: auto;
            text-align: right;
            .coinbalance{
                font-size: 24px;
                color: #000;
                span{
                    margin-left: 6px;
                }
            }
            .coinWon{
                color: #000;
                font-size: 18px;
            }
        }   
    }
    .btnBox{
        display: flex;
        align-items: center;
        margin-top: 24px;
        &>div:first-child{
            flex: 1;
            text-align: center;
            background-color: #f56565;
            border: 1px solid #fff2;
            line-height: 36px;
            color: #fff;
            border-radius: 36px;
            margin: 0 16px;
            cursor: pointer;
        }
        &>div:last-child{
            flex: 1;
            text-align: center;
            background-color: #8688ff;
            border: 1px solid #fff2;
            line-height: 36px;
            color: #fff;
            border-radius: 36px;
            margin: 0 16px;
            cursor: pointer;
        }
    }
    .listTable{
        border: 1px solid #fff3;
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 8px;
        .wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .title{
                display: flex;
                align-items: center;
                .tag{
                    border: 1px solid #fff;
                    border-radius: 36px;
                    padding: 2px 16px;
                }
                .amount{
                    font-size: 20px;
                    margin-left: 6px;
                }
            }
            .address{
                color: #888;
                word-break: keep-all;
                margin: 6px 0;
                &.link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }        
        .date{
            width: 100%;
            font-size: 14px;
            text-align: right;
        }        
    }
    .noList{
        width: 100%;
        text-align: center;
        margin: 32px 0;
    }
    .trade-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
}

#receive{
    .walletBtn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        margin-bottom: 24px;
        button{
            // width: 100px;
            background-color: #000;
            border: 1px solid #fff4;
            border-radius: 36px;
            height: 36px;
            color: #fff;
            font-size: 14px;
            // margin-left: 8px;
        }
    }
    .qrBox{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .address{
            color: #888;
            margin: 16px 0;
        }
    }
    .priceBox{
        display: flex;
        align-items: center;
        justify-content: center;
        .inputbox{
            border-bottom: 1px solid #888;
            margin: 0 6px;
            input {
                background-color: #000;
                color: #fff;
                border: none;
                text-align: center;
            }
        }
       
    }
}
#send{
    .formContainer{       
        .mybalance{
            font-size: 24px;
            color: #000;
            margin-bottom: 10px;
            .balance{
                margin-left: auto;
                color: $mainColor;
                font-weight: 500;
                margin-left: 6px;
            }
        }
        .myAvailablebalance{
            font-size: 18px;
            color: #000;
            margin-bottom: 20px;
            .balance{
                margin-left: auto;
                color: $mainColor;
                font-weight: 500;
                margin-left: 6px;
            }
        }
        .formBox{
            width: 100%;
            height: 48px;
            background-color: #fff;
            display: flex;
            align-items: center;
            border-radius: 48px;
            margin-bottom: 16px;
            input{
                background-color: inherit;
                width: 80%;
                border: none;
                outline: 0;
                color:#000;
                margin: 0;
                margin-left: 16px;
            }
        }    
        p{
            margin-left: 10px;
            margin-bottom: 10px;
        }   
    }   
    .sendInfo{
        .title{
            color: #888;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 24px;
        }
        .content{
            margin-top: 16px;
            word-break: keep-all;
        }
        .loginBtn{
            font-family: $font-family;
            font-size: 16px;
            font-weight: 600;
            border-radius: 48px;
            height: 48px;
            width: 320px;
            margin-top: 48px;
            box-shadow: none;
            outline: 0;
            background-color: $mainColor;
            border: none;
            cursor: pointer;
        }
    }
}
#myinfo{
    .container{       
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        .title{
            color:#000;
        }
        .formBox{
            margin-bottom: 0;
            input {
                margin-left: 16px;
                text-align: right;
                &::placeholder{
                    text-align: left;
                }
            }
        }
        .objValue{
            color: #888;
        }
    }
    @media screen and (max-width: 599px) {
        .container2{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .box {
                border-bottom: 1px dashed #fff3 ;
                margin-bottom: 16px;
            }
            .box2{
                display: flex;
                flex-direction: column;
                align-items: center;                
            }
            .title{
                margin-bottom: 16px;
            }
            input {
                width: 100%;
                margin: 0 16px;
            }
            .otykey{
                color:#000;
                margin: 16px 0;
            }
        }
        .btnBox{
            display: flex;
            align-items: center;
            justify-content: center;
            .loginBtn{
                margin-top: 16px;
            }
        }      
    }
    @media screen and (min-width: 600px) {
        .container2{
            display: flex;
            align-items: center;
            .box,
            .box2{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .title{
                margin-bottom: 16px;
            }
            .box2 {
                height: 100%;
                border-left: 1px dashed #fff3;
            }
            input {
                width: 100%;
                margin: 0 16px;
            }
            .otykey{
                color:#000;
                margin: 16px 0;
            }
        }
        .btnBox{
            display: flex;
            align-items: center;
            justify-content: center;
            .loginBtn{
                margin-top: 16px;
            }
        }      
    }
    .noModifyId{
        margin-left: auto;
        margin-right: 24px;
    }
    .myinfoBtn{
        border-radius: 36px;
        width: 100px;
        height: 36px;
        color: #fff;
        border: 1px solid #fff3;
        background-color: #ff67b0;
        cursor: pointer;
        font-size: 14px;
        font-weight: 300;
    }
    .myinfoBtn.close{
        background-color: inherit;
        border:1px solid #fff3;
    }
    .myinfoBtn.sub{
        margin-right: 24px;
    }
    .myinfoBtn.otp{
        width: 120px;
        margin-left: auto;
        &.active{
            color: #888;
        }
    }
    .myinfoBtn.saveBtn{
        margin-left: 10px;
        border:none;
        background-color: $mainColor;
        color:#fff;
        font-weight: bold;
    }
    .kycStatus{
        .completed{
            color:$mainColor;
            margin: 0 6px;
        }
    }
    .checkIdentity{        
        width: 320px;    
    }
    .container3{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .info{
            text-align: center;
            word-break: keep-all;
            margin: 16px 0;
        }
        .formBox{
            width: 100%;
            margin-top: 16px;
            input{
                margin: 0 16px;
            }
        }
    }
}
.ant-switch.ant-switch-checked{
    background-color: $mainColor;
}

#inquiry{
    .inquiryTitle{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .mainTitle{
            color: #000;
        }
        .moreBtn {
            border-radius: 36px;
            width: 100px;
            line-height: 36px;
            text-align: center;
            color: #fff;
            border: 1px solid #fff3;
            background-color: #000;
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
        }
    }
    .question{
        border: 1px solid #888;
        border-radius: 36px;
        color: #888;
        padding: 2px 10px;
    }
    .answer{
        border: 1px solid $mainColor;
        border-radius: 36px;
        color: $mainColor;
        padding: 2px 10px;
    }
    .content{
        .admin{
            color: $mainColor;
            margin-bottom: 6px;
        }
    }
}

#modifyPw {    
    .container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .logoImg{
            width: 240px;
            margin-bottom: 40px;
        }      
        .formBox{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            input {
                width: 90%;
            }
        }
        .btnBox{
            margin-top: -30px;
        }
        .cancel{
            color: #888;            
            padding: 20px 0;
            cursor: pointer;
            font-size: 14px;
        }
        .findPwResult{
            color:#fff;
            text-align: center;
            .icon{
                font-size: 24px;
                margin-bottom: 6px;
                color: $mainColor;
            }
            .btnBox{
                margin-top: 16px;
            }
        }
    }
}
#signup{
    .header{
        height: 56px;
        display: flex;
        align-items: center;
        .globalImg{
            width: 20px;
            height: 20px;
            padding: 10px;
            margin-left: auto;
            cursor: pointer;
        }       
    }
    .welcome{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        img{
            width: 400px;
            margin : 0 auto;
        }
        p{
            margin: 24px 0;
            font-size: 22px;
        }
    }    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .ant-form, .formBox{
           width: 100%;
           font-family: $font-family;
           color: #888;
           margin: 0;
            .title{
                margin-bottom: 6px;
                margin-top: 16px;
                margin-left: 16px;
            }
            input{
                margin-left: 16px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
                &[type=number] {
                    -moz-appearance: textfield;
                }            
           }
           .overlap-button{
            margin-left: auto;
            border-radius: 36px;
            width: 100px;
            line-height: 36px;
            text-align: center;
            color: #fff;
            border: 1px solid #fff3;
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
            margin-right: 16px;
            background-color: $mainColor;
            &.ok{
                background-color: #b5b5b5;
                border: 1px solid $mainColor
            }
           }
        }
        .option{
            display: flex;
            align-items: center;
        }
        .btnbox{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .resend-btn-ok{
            font-family: $font-family;
            font-size: 16px;
            border-radius: 48px;
            height: 48px;
            width: 320px;
            margin-top: 24px;
            box-shadow: none;
            outline: 0;
            border: 1px solid #fff;
            background-color: #000;
            color:#fff;
            cursor: pointer;
        }
    }
}
#signupok{
    .resend-btn-ok{
        font-family: $font-family;
        font-size: 16px;
        border-radius: 48px;
        height: 48px;
        width: 320px;
        margin-top: 24px;
        box-shadow: none;
        outline: 0;
        border: 1px solid #fff;
        background-color: #000;
        color:#fff;
        cursor: pointer;
    }
}
.ant-form-item-explain-error{
    text-align: right;
    margin-right: 16px;
}

#signupok{
    height: 100%;
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width: 220px;
            margin : 0 auto;
        }
        .welcome{
            margin: 24px 0;
            font-size: 22px;
            color: $mainColor;
        }
        .content{
            margin-top: 60px;
            color:#333;
            text-align: center;
            font-size: 16px;
        }
    }
    .btnbox{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.ant-modal-content{
    max-height: 80vh;
    overflow:scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
}
#exchange {
    width: 100%;
    .exchangeCoinBox, .exchangeCoinBox2{
        background: linear-gradient(90deg, rgba(252,253,142,0.5), rgba(188,255,232,0.5));
        border-radius: 10px;
        padding: 16px;
        &> div:first-child{
            margin-bottom: 10px;
        }
        .line {
            display: flex;
            align-items: center;
            color: #000;
            justify-content: center;
            .title{
                padding: 0 5px;
                color: #705DF6;
                font-weight: bold;
                font-size: 18px;
            }
            .content {
                color: #666;
            }
            .contentBalance {
                color: #000;
                font-weight: bold;
            }
            .coinImg{
                background-color: #fff;
                width: 32px;
                height: 32px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-right: 16px;
                object-fit: contain;
                img{
                    width: auto;
                    height: auto;
                    max-width: 32px;
                    max-height: 32px;
                }
            }
        }
    }
    .flipBtn {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
    .inputWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        input {
            padding: 5px;
        }
    }
    .priceLine{
        display: flex;
        align-items: flex-start;
        color: #666;
        margin-bottom: 10px;
        &> div:first-child {
            margin-right: 16px;
            width: 60px;
        }
    }
    .exchangeButtonWrapper {
        text-align: center;
        margin-top: 30px;
        .saveBtn {
            width: 100%;
            border:none;
            background-color: $mainColor;
            color:#fff;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            border-radius: 36px;
            padding: 10px;
        }
    }
}

.exchangeInputBorder {
    margin-left: 8px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 15px;
    border: #c1c1c1 solid 1px;
}
.exchangeInput{
    border: none;
    background-color: transparent;
}